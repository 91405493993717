.disponibilidad {
	width:100%;
	height:auto;
	border-collapse:collapse;
	padding:5px;
}
.disponibilidad th {
	padding: 5px;
    background: #91cc1b;
    color: white;
    text-align: left;
}
.disponibilidad td {
	padding:5px;
	color: #1b668b;
	height: 55px;
	font-size: 18px;
}
.sugerencias {
	width:100%;
	height:auto;
	border-collapse:collapse;
	padding:5px;
}
.sugerencias th {
	padding: 5px;
    background: #04b5af;
    color: white;
    text-align: left;
}
.sugerencias td {
	padding:5px;
	color: #1b668b;
	height: 55px;
	font-size: 18px;
}
.width-50{
	width: 50%;
}
/*search domain*/
.dominio-search input{
    width: 90%;
    border: solid 1px #c8c8c8;
    background-color: #fafafafa;
    border-radius: 0;
}
.dominio-search input::placeholder {
    color: #1b668b;
    font-size: 18px;
}
.activar-dominio{
    justify-content: center !important;
}
.centrar-btn{
	display: flex;
    flex-direction: row;
    justify-content: center;
}
@media screen and (max-width:700px){
	.disponibilidad th {
	    font-size: 12px;
	}
	.disponibilidad td {
	    font-size: 12px;
	}
	.sugerencias th {
	    font-size: 12px;
	}
	.sugerencias td {
	    font-size: 12px;
	}
	.text-center-responsive{
		text-align: center !important;
	}
	.btn-azul-responsive{
	    padding: 10px 5px !important;
	    font-size: 12px !important;
	}
}