.scroll-navbar{
    left: 0;
    width: 100%;
    position: absolute;
    z-index: 1000;
    padding: 0;
    background-color: #fafafa;
}
.row-login{
    height: 5px;
    list-style: none;
    justify-content: flex-end;
    align-items: baseline;
    padding: 1rem 15px 1rem 15px;
}
.row-login > button {
    background-color: #fafafa;
    border:none;
}
.row-login > button:hover {
    background-color: #fafafa;
}
.row-menu{
    align-items: baseline;height: 120px;font-family: GothamBlack;
}
.logo-menu{
    width: auto;height: 100px;
}
.menu{
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: space-between;
}
.opccion-menu{
    color: #1b668b;
    font-size: 18px;
}
.selected {
    outline: 0;
    border-bottom: solid 1px #e5044e;
    color: #91cc1b;
    padding-bottom: 5px;
}
.menu a:active, .menu a:hover {
    outline: 0;
    border-bottom: solid 1px #e5044e;
    color: #91cc1b;
    padding-bottom: 5px;
}
.menu .opccion-menu-active {
    outline: 0;
    border-bottom: solid 1px #e5044e;
    color: #91cc1b;
    padding-bottom: 5px;
}
.check-falso{
    display: none;
}
a {
    text-decoration: none !important;
}
/*Menu lateral*/
.menu-lateral-contenedor{
    font-family: GothamBlack;
    position: fixed;
    width: 10.5rem;
    left: 0;
    z-index: 1000;
    padding: 0;
    background-color: #fafafa;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 0 5px 5px 0;
}
.menu-lateral{
    flex-direction: column !important;
    padding: 1rem;
}
.opccion-menu-lateral{
    margin-bottom: 15px;
}
a.opccion-menu.opccion-menu-lateral{
    padding-bottom: 0 !important;
    border-bottom: none !important;
}
.progress{
    border-right: solid 2px #e5044e;
    height: 80%;
    float: left;
    margin-top: 30px;
}
.menu .opccion-menu-active span{
    width: 10px;
    height: 10px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #e5044e;
    position: absolute;
    margin-top: 5px;
    left: 15.5%;
}
/*iniciar sesion*/
.opccion-login-ces{
    color: #91cc1b;
    font-size: 18px;
    cursor: pointer;
    font-family: GothamBlack;
    display: flex;flex-direction: row;
}
.opccion-login-ces > a{
    color: #91cc1b;
    font-size: 18px;
    cursor: pointer;
    font-family: GothamBlack;
    display: flex;flex-direction: row;
}
.linea-verde{
    border-right: solid 2px #91cc1b;
    margin: 0 20px;
}
.opccion-login{
    color: #1b668b;
    font-size: 18px;
    cursor: pointer;
    font-family: GothamBlack;
}
.img-sesion{
    width: 20px;
    height:auto;
    margin-right: 5px;
}
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    margin-top: 2.2rem;
}
.MuiList-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    color: #1b668b;
}
.img-flecha-drop{
    width: 12px;
    margin-left: 5px
}
.mostrar{
    display: none;
}

/*Responsive del NavBar*/
@media only screen and (min-width:320px) and (max-width: 1439px){
    .ocultar-menu-lateral{
        display: none;
    }
}
@media only screen and (min-width:768px) and (max-width: 991px){
    .container-especial{
        max-width: 980px !important;
    }
    .titulo-banner{
        font-size: 26px !important;
    }
    .texto-banner {
        font-size: 14px !important;
    }
    .padding-0{
        padding: 0;
    }
    .menu{
        padding: 0;
    }
    .opccion-menu {
        font-size: 14px;
    }
}
@media only screen and (max-width:768px) {
    .padding-top-18 {
        padding-top: 6rem !important;
    }
    .ocultar{
        display: none;
    }
    .mostrar{
        display: block;
    }
    .ocultar > .row-menu{
        height: auto;
    }
    .sox{
        height: auto;
    }
    .w-4{
        width: 43%;
    }
    .row-responsive-user{
        display: flex;
        flex-direction: row;
    }
    .opccion-login-ces{
        display: none;
    }
    .logo-menuhome-responsive{
        display: none;
    }
    .logo-menu-responsive{
        display: block;
        width: 40px;
        height: auto;
        margin-bottom: 1rem;
    }
    .img-responsive-navbar {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
    }
    .row-login {
        height: auto;
        justify-content: center;
    }
    .check-falso{
        display: block;
    }
    .row-menu{
        display: block;
    }
    #menuToggle{
        display: block;
        position: relative;
        top: 17px;
        left: 0;
        z-index: 1;
        -webkit-user-select: none;
        user-select: none;
        height: 50px;
    }
    #menuToggle a{
        text-decoration: none;
        color: #1b668b;
        transition: color 0.3s ease;
        display: block;
        font-size: 18px;
        margin: 0 0 15px 0px;
    }
    #menuToggle input
    {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: -7px;
        left: 0;
        cursor: pointer;
        opacity: 0;
        z-index: 2; 
        -webkit-touch-callout: none;
    }
    #menuToggle span{
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        background: #343a40;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        opacity 0.55s ease;
    }
    #menuToggle span:first-child{
        transform-origin: 0% 0%;
    }
    #menuToggle span:nth-last-child(2){
        transform-origin: 0% 100%;
    }
    #menuToggle input:checked ~ span{
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
        background: #232323;
    }
    #menuToggle input:checked ~ span:nth-last-child(3){
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }
    #menuToggle input:checked ~ span:nth-last-child(2){
        transform: rotate(-45deg) translate(0, -1px);
    }
    #menu{
        display: block;
        width: auto;
        margin: -45px 0 0 -60px;
        padding: 50px;
        padding-top: 50px;
        background: #ffffff;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        transform-origin: 0% 0%;
        transform: translate(-100%, 0);
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    }
    #menu li{
        padding: 8px 0;
        font-size: 18px;
    }
    #menuToggle input:checked ~ ul{
        transform: none;
    }           
}