.dominio-search{
	width: 85%;
	display: flex;
    flex-direction: row;
    justify-content: left;
}
.texto-gordo {
	font-family: GothamUltra;
}
.padding-formulario{
	padding: 50px;
}
.margin-0{
	margin: 0;
	padding-left: 0;
}
.contenedor-error-message{
	width: 76%;
}
.img-lupa{
	width: 30px;
    height: 30px;
    background-color: #fafafa;
    margin: 5px 0;
    margin-left: -3rem;
}
.margin-left{
	margin-left: -15px;
}
@media screen and (max-width:768px){
	.contenedor-error-message{
		width: 100%;
		float: right;
	}
	.dominio-search{
		width: 100%;
		display: flex;
    	flex-direction: row;
	}
	.dominio-search input{
		width: 100%;
	}
	.padding-formulario{
		padding: 0;
	}
}