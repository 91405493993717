/*Home*/
/*banner*/
.contenedor-banner{
    padding-top: 10rem;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}
.contener-absoluto{
    position: absolute;
    width: 100%;
}
.borde-general-banner{
    border: solid 1px white;
    padding: 28px;
    text-align: center;
}
.cont-opciones-dominio{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.linea-horizontal{
    border-bottom: solid 1px white;
    width: 40%;
}
.img-banner{
    width: 100%;
    height: auto;
}
.img-banner-responsive{
    width: 100%;
    height: auto;
    height: 32rem;
}
.contenedor-premium{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 1rem;
    cursor: pointer;
}
.btn-premium{
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
}
.img-premium{
    width: 12rem;height: auto;
}
.img-premiumqueen{
    left: 13%;
    width: auto;
    position: absolute;
    height: 19px;
}
.span-clic-premium{
    position: absolute;
    left: 41%;
    color: black;
    font-family: GothamBlack;
}
/*search domain*/
.contenedor-btn-dominio{
    display: flex;flex-direction: row;justify-content: center;
}
.search-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.search-container input{
    width: 90%;
    border: none;
    height: 40px;
}
.search-container input::placeholder {
    color: #1b668b;
    font-size: 18px;
}
/*suscripciones*/
.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: auto;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    padding: 15px;
    text-align: center;
    margin-bottom: 2rem;
}
.card:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.card-titulo{
    font-size: 24px;
    font-family: GothamBlack;
}
.precio{
    font-family: GothamBlack;
    font-size: 34px;
    text-align: center;
}
.titulo-meses{
    font-size: 30px;
    font-family: GothamBlack;
}
.card-texto{
    color: #1b668b;
    font-size: 16px;
}
.number-size{
    font-family: GothamBlack;
    font-size: 34px;
}
.texto-contactanos{
    color: #1b668b;
    font-size: 18px;
    float: left;
}
.color-1{
    color: #6fcd00;
}
.color-2{
    color: #00b6b0;
}
.color-3{
    color: #ff1138;
}
.color-4{
    color: #ffaf00;
}
.color-5{
    color: #efa4c9;
}
.color-6{
    color: #009979;
}
/*Como funciona*/
.height-profit{
    height: 16rem;
}
.height-parrafo{
    height: 5rem;
}
.contenedor-btn-dow{
    display: flex;
    flex-direction: column;
    align-items: center;
}
/*regimen*/
.regimen-input{
    height: auto;
}
.texto-regimen{
    font-family: GothamLight;
}
.regimen-1{
    padding: 0 10px;
}
.regimen-2{
    padding: 0 8px;
}
.MuiDialog-paperScrollPaper {
    max-height: calc(100% - 10px) !important;
}
.MuiDialogContent-root {
    overflow-y: initial !important;
}
/*input check*/
.container-check {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.container-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark-check {
    position: absolute;
    top: 0;
    left: 56%;
    height: 25px;
    width: 25px;
    background-color: white;
    border-radius: 5px;
    border: solid 1px #1b668b;
}
.container-check:hover input ~ .checkmark-check {
    background-color: #1b668b;
}
.container-check input:checked ~ .checkmark-check {
    background-color: #1b668b;
}
.checkmark-check:after {
    content: "";
    position: absolute;
    display: none;
}
.container-check input:checked ~ .checkmark-check:after {
    display: block;
}
.container-check .checkmark-check:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.items-plantilla-classic{
    list-style-type: square;
    font-size: 20px;
}
/*template*/
.template-scroll{
    display:block;
    text-align: center;
    color: #fff;
    padding-top: 20px;;
    width:100%;
    height:500px;

    margin-left: auto!important;
    margin-right: auto!important;
    background-position: top center;
    background-size: 100% auto!important;
    background-repeat: no-repeat;

    -webkit-transition: background-position 11s ease-in-out;
    -moz-transition: background-position 11s ease-in-out;
    -ms-transition: background-position 11s ease-in-out;
    -o-transition: background-position 11s ease-in-out;
    transition: background-position 11s ease-in-out;
}
#classic .template-scroll{
    background:url(https://chappsybucked.s3.amazonaws.com/chappsy/templates_preview/Classic.png) 0 0 no-repeat;
}
#classic .template-scroll:hover {
    background-position:0px 109%;
}
#natural .template-scroll{
    background:url(https://chappsybucked.s3.amazonaws.com/chappsy/templates_preview/Natural+Food.png) 0 0 no-repeat;
}
#natural .template-scroll:hover {
    background-position:0px 106%;
}
#electronic .template-scroll{
    background:url(https://chappsybucked.s3.amazonaws.com/chappsy/templates_preview/Electronic_Bus.png) 0 0 no-repeat;
}
#electronic .template-scroll:hover {
    background-position:0px 108%;
}
.layout-image {
    position: relative;
    overflow: hidden;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.15);
}
.layout-image .preview-btn {
    font-weight: 400;
    font-size: 16px;
    left: 0;
    margin: 0 auto;
    padding: 12px 24px;
    position: absolute;
    right: 0;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    bottom: 0;
    opacity: 0;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    z-index: 9;
}
.layout-image:hover .preview-btn {
    opacity: 1;
    background: #91cc1b none repeat scroll 0 0;
    color: #ffffff;
}
/*Planes*/
.width-table-planes-1{
    background-color: #f4f4f4;
    color: #1b668b;
    font-family: GothamUltra;
    font-size: 24px;
    font-weight: 400;
    padding:15px;
    width: 76%;
}
.width-table2{
    background-color: #008de6;
    color: white;
    font-family: GothamUltra;
    font-size: 18px;
    font-weight: 400;
    padding:15px;
    width: 12%;
}
.width-table3{
    background-color: #f9ad00;
    color: white;
    font-family: GothamUltra;
    font-size: 18px;
    font-weight: 400;
    padding:15px;
    width: 12%;
}
.width-table3 span {
    font-size: 16px;
}
.cont-btn-planes{
    margin: 2rem 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.icono-check-planes{
    font-size: 24px;
    color: #6fcd00;
}
.table-planes {
    border:1px solid #F1F1F1;
    border-collapse:collapse;
    padding:15px;
}
.table-planes td {
    color: #1b668b;
    border:1px solid #F1F1F1;
    padding:15px;
    background:#FFFFFF;
}
/*Responsive*/
@media (max-width: 1199px){
    .btn-planes {
        padding: 10px 20px !important;
        width: 6.5rem !important;
        font-size: 16px !important;
    }
}
@media screen and (min-width:768px) and (max-width:1023px){
    .height-profit {
        height: 18rem;
    }
    .titulo2 {
        font-size: 20px !important;
    }
    .titulo-banner {
        font-size: 30px !important;
        margin-bottom: 1rem;
    }
    .search-container input {
        width: 80%;
    }
    .borde-general-banner {
        padding: 15px;
    }
}
@media screen and (min-width:320px) and (max-width:768px) {
    .opccion-menu {
        font-size: 16px !important;
    }
    .titulo2 {
        font-size: 20px !important;
    }
    .texto-general {
        font-size: 16px !important;
    }
    .arrow{
        display: none;
    }
    .col-md-3.items-card {
        margin-bottom: 1rem;
    }
    .btn-dow {
        width: auto !important;
    }
    .titulo-banner {
        font-size: 30px !important;
        margin-bottom: 1rem;
    }
    .search-container input {
        width: 80%;
    }
    .borde-general-banner {
        padding: 15px;
    }
}
@media screen and (max-width: 767px) {
    .margin-bottom-20{
        margin-bottom: 5rem !important;
    }
    .wi-responsive{
        width: 73%;
    }
    img.img-dow {
        margin-right: 15px;
    }
    .mostrar-home{
        display: none;
    }
    .parent-home { 
        display: grid; 
        grid-template-columns: 1fr; 
        grid-template-rows: repeat(2, 1fr); 
        grid-column-gap: 15px;
        grid-row-gap: 25px;
    }
    .items-aling-number{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .responsive-top-3{
        margin-top: 3rem;
    }
    .img-icono-2 {
        height: 90px !important;
        width: 90px !important;
    }
    .slick-center .img-icono-2 {
        height: 90px !important;
        width: 90px !important;
    }
    .margin-bottom-responsive{
        margin-bottom: 4rem !important;
    }
    .MuiDialogTitle-root {
        padding: 16px 0 !important;
    }
    .MuiDialogContent-root {
        padding: 8px 0 !important;
    }
    .regimen-1 {
        padding: 0 0;
    }
    .regimen-2 {
        padding: 0 0;
    }
    .checkmark-check {
        left: 87%;
    }
    .height-profit {
        height: auto;
    }
    .contenedor-premium{
        display: block;
    }
    .img-premium {
        width: 100%;
        height: 55px;
    }
    .img-premiumqueen {
        left: 24%;
    }
    .span-clic-premium {
        left: 46%;
    }
    .btn-dominio {
        width: 100%;
    }
    .btn-planes {
        padding: 10px 12px !important;
        width: 5rem !important;
        font-size: 14px !important;
    }
}
@media (max-width: 768px){
    .contenedor-banner {
        padding-top: 3rem;
    }
}
/*responsive especialmente para la tabla de planes*/
@media (max-width: 375px){
    .width-table-planes-1 {
        font-size: 12px !important;
    }
    .width-table2 {
        font-size: 10px !important;
        padding:5px;
    }
    .width-table3 {
        font-size: 10px !important;
        padding:5px;
    }
    .width-table3 span {
        font-size: 10px !important;
    }
    .table-planes td {
        font-size: 10px !important;
        padding: 8px !important;
    }
    .icono-check-planes {
        font-size: 10px !important
    }
    .btn-planes {
        padding: 10px 3px !important;
        width: 3.5rem !important;
        font-size: 12px !important;
        margin-left: 0.1rem !important;
    }
}