.footer{
	background-color: #91cc1b;
}
.logo-redes{
	height: 50px;
    width: 50px;
    padding: 5px;
}
.texto-footer{
	color: white;
    font-size: 18px;
}
.padding-top-15{
	padding-top: 15px;
}
@media screen and (min-width:320px) and (max-width:767px) {
	.row.margin-bottom-3.padding-top-15 {
	    text-align: center;
	}
}