/*General*/
@font-face {
    font-family: GothamUltra;
    src:url(./fonts/Gotham-Ultra.otf);
}
@font-face {
    font-family: GothamBlack;
    src:url(./fonts/Gotham-Black.otf);
}
@font-face {
    font-family: GothamLight;
    src:url(./fonts/Gotham-Light.otf);
}
body{
    font-family: GothamLight;
    margin: 0;
    overflow: auto !important;
    padding-right: 0 !important;
    overflow-x: hidden !important;
}
body.nimbus-is-editor {
    padding-right: 0 !important;
    overflow: inherit !important;
    overflow-x: hidden !important;
}
.text-left{
    text-align: left;
}
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.text-justify{
    text-align: justify;
}
.float-left{
    float: left;
}
.float-right{
    float: right;
}
.row-flex{
    display: flex;
    flex-direction:row;
    align-items: center;
}
.row-wrap{
    display: flex;
    flex-wrap: wrap;
}
.columna-flex{
    display: flex;
    flex-direction: column;
}
.titulo-banner{
    margin: 0;
    font-size: 42px;
    font-family: GothamBlack;
}
.texto-banner{
    font-family: GothamBlack;
    font-size: 18px;
}
.titulo1{
    font-size: 45px;
    font-family: GothamUltra;
}
.titulo2{
    font-size: 24px;
    font-family: GothamBlack;
}
.titulo3{
    font-size: 18px;
    font-family: GothamBlack;
}
.texto-general{
    color: #1b668b;
    font-size: 18px;
}
.font-weight-600{
    font-weight: 600;
}
.cursor-pointer{
    cursor: pointer;
}
.color-white{
    color: white;
}
.color-blue{
    color: #1b668b;
}
.color-green{
    color: #91cc1b;
}
.color-fucsia{
    color: #e5044e;
}
.color-red{
    color: red;
}
.color-aqua{
    color: #04b5af;
}
.margin-0{
    margin: 0;
}
.margin-top-1{
    margin-top: 1rem;
}
.margin-top-2{
    margin-top: 2rem;
}
.margin-top-3{
    margin-top: 3rem;
}
.margin-top-5{
    margin-top: 5rem;
}
.margin-bottom-m2{
    margin-bottom: -2rem;
}
.margin-bottom{
    margin-bottom: 10rem;
}
.margin-bottom-1{
    margin-bottom: 1rem;
}
.margin-bottom-3{
    margin-bottom: 3rem;
}
.margin-bottom-20{
    margin-bottom: 20rem;
}
.margin-left-1{
    margin-left: 1rem;
}
.margin-left-2{
    margin-left: 2rem;
}
.padding-top-10{
    padding-top: 10rem;
}
.padding-top-18{
    padding-top: 18rem;
}
.line-green{
    border-left: solid 2px #91cc1b;
}
/*images*/
.img-general{
    width: 100%;
    height: auto;
}
.logo-visanet{
    width: 70px;
    height: auto;
}
.img-items{
    width: 140px;
    height: 140px;
    margin-bottom: 1rem;
}
.img-dow{
    width: 20px;
    height: auto;
    margin-right: 20px;
}
.img-asesor{
    width: auto;
    height: 250px;
}
/*botones*/
.btn-dominio{
    color: white;
    font-weight: 600;
    font-size: 18px;
    padding:10px 40px;
    background-color: #e5044e;
    border-radius: 5px;
    cursor: pointer;
}
.btn-dominio:hover , .btn-dominio:active{
    outline: 0;
    background-color: #bc0140;
    cursor: pointer;
}
.btn-search {
    padding: 10px;
    height: 40px;
    outline: none;
    color: white;
    font-size: 18px;
    background-color: #91cc1b;
    cursor: pointer;
}
.btn-search:hover , .btn-search:active{
    outline: 0;
    background-color: #6d9c0f;
    border: solid 1px #6d9c0f;
    cursor: pointer;
}
.btn-principal{
    color: white;
    font-weight: 600;
    font-size: 18px;
    padding:10px 40px;
    background-color: #91cc1b;
    border-radius: 5px;
    cursor: pointer;
}
.btn-principal:hover , .btn-principal:active{
    outline: 0;
    background-color: #6d9c0f;
    cursor: pointer;
}
.btn-azul{
    color: white;
    font-weight: 600;
    font-size: 18px;
    padding:10px 40px;
    background-color: #1b668b;
    border-radius: 5px;
    margin: 0 5px; 
}
a.btn-azul:hover , a.btn-azul:active{
    outline: 0;
    background-color: #0b3c54;
    cursor: pointer;
}
.btn-group-blue{
    color: white;
    font-weight: 600;
    font-size: 18px;
    padding: 10px 40px;
    background-color: #1b668b;
    border-radius: 5px 0 0 5px;
}
a.btn-group-blue:hover , a.btn-group-blue:active{
    outline: 0;
    background-color: #0b3c54;
    cursor: pointer;
}
.btn-group-green{
    color: white;
    font-weight: 600;
    font-size: 18px;
    padding: 10px 40px;
    background-color: #91cc1b;
    border-radius: 0 5px 5px 0;
}
a.btn-group-green:hover , a.btn-group-green:active{
    outline: 0;
    background-color: #6d9c0f;
    cursor: pointer;
}
.btn-dow{
    width: auto;
    padding: 8px 0.5rem;
    background-color: #e5044e;
    color: white;
    font-weight: 600;
    border-radius: 5px;
    justify-content: space-between;
}
a.btn-dow:hover , a.btn-dow:active{
    outline: 0;
    background-color: #bc0140;
    cursor: pointer;
}
.btn-planes {
    color: white;
    font-weight: 600;
    font-size: 18px;
    background-color: #91cc1b;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 25px;
    width: 11%;
}
.btn-principal:hover , .btn-principal:active{
    outline: 0;
    background-color: #6d9c0f;
    cursor: pointer;
}
.ocultar-banner{
    display: block;
}
.mostrar-banner{
    display: none;
}
/*Responsive*/
@media screen and (min-width:1440px) {
    .contener-absoluto {
        top: 25%; 
    }
}
@media screen and (min-width:426px) and (max-width:767px) {
    .row-flex{
        display: block;
    }.columna-flex{
        display: block;
    }
    .titulo1 {
        font-size: 40px;
        text-align: center;
    }
    .line-green{
        border-left: solid 2px white;
    }
    .padding-top-18 {
        padding-top: 10rem;
    }
    .ocultar-banner{
        display: none;
    }
    .mostrar-banner{
        display: block;
    }
    .container.container-especial.padding-0 {
        margin-top: 5rem;
    }
}
@media screen and (min-width:320px) and (max-width:425px) {
    .row-flex{
        display: block;
    }.columna-flex{
        display: block;
    }
    .titulo1 {
        font-size: 35px;
        text-align: center;
    }
    .line-green{
        border-left: solid 2px white;
    }
    .padding-top-18 {
        padding-top: 10rem;
    }
    .ocultar-banner{
        display: none;
    }
    .mostrar-banner{
        display: block;
    }
    .container.container-especial.padding-0 {
        margin-top: 3rem;
    }
    .width-table-planes-1 {
        font-size: 18px;
    }
    .width-table2 {
        font-size: 14px;
    }
    .width-table3 {
        font-size: 14px;
    }
    .width-table3 span {
        font-size: 12px;
    }
    .table-planes td {
        font-size: 12px;
        padding: 8px !important;
    }
}
@media (max-width: 767px){
    .container, .container-sm {
        max-width: 720px !important;
    }
}