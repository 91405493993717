.slick-next:before, .slick-prev:before {
    color: #1b668b;
}
.img-icono-2{
    margin-top: 5px;
	height: 50px;
	width: 50px;
	display: initial !important;
}
.slick-center  .img-icono-2{
    height: 50px;
    width: 50px;
    box-shadow: 0px 0px 7px #989898;
    display: initial !important;
    border-radius: 50%;
    margin-top: 5px;
}
.slick-current .carousel2{
	color: #91cc1b !important;
}
.slick-slide .carousel2{
	font-size: 20px;
    font-family: GothamBlack;
    color:  #1b668b;
}
.slick-prev{
	left: -1px !important;
    background: white !important;
    z-index: 4;
    height: 100%;
    width: 5%;
}
.slick-next{
	right: -1px !important;
    background: white !important;
    z-index: 4;
    height: 100%;
    width: 5%;
}
.alto-parrafo{
    height: 5rem;
}
@media only screen and (min-width:426px) and (max-width:768px) {
    .columna-flex{
        display: flex !important;
    }
    .slick-prev{
        width: 6%;
    }
    .slick-next{
        width: 6%;
    }
    .margin-top-5{
        margin-top: 1rem !important;
    }
}
@media only screen and (min-width:320px) and (max-width:425px) {
    .columna-flex{
        display: flex !important;
    }
    .slick-prev{
        width: 14%;
    }
    .slick-next{
        width: 14%;
    }
    .margin-top-5{
        margin-top: 1rem !important;
    }
}