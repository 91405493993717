.text-error-codigo {
    float: inherit;
}
.codigo-error{
	display: flex;
    flex-direction: column;
    width: 40%;
    align-items: flex-end;
    left: 30%;
}
.ajuste-input{
    width: 60%;
    left: 20%;
}
.olvidar-contra:hover{
    color: #6d9c0f;
    cursor: pointer;
}
input.border-blue:focus {
    border: solid 2px #1b668b;
}
/*Responsive*/
@media screen and (min-width:320px) and (max-width:767px) {
    .codigo-error{
        width: 100%;
        left: 0;
    }
    .ajuste-input{
        width: 100%;
        left: 0;
    }
}