/*suscripcion*/
input{
    padding: 10px;
    height: 40px;
    margin-bottom: 1rem;
    outline: none;
    color: #5f5f5f;
    font-size: 16px;
    font-family: GothamLight;
}
textarea {
    padding: 10px;
    margin-bottom: 0.7rem;
    color: #5f5f5f;
    outline: none;
    font-size: 16px;
    font-family: GothamLight;
}
input.check-input {
    padding: 0;
    height: auto;
    margin-bottom: auto;
}
.border-blue{
    width: 100%;
    border: solid 1px #1b668b;
    border-radius: 5px;
}
.border-red{
    width: 100%;
    border: solid 1px red;
    border-radius: 5px;
}
.texto-subs{
    color: #1b668b;
    font-size: 14px;
}
.texto-error{
    color: red;
    font-size: 10px;
    float: right;
    margin-top: -12px;
}
.texto-uploadfile{
    color: #91cc1b;
    margin-top: 2rem;
    font-size: 14px;
    font-weight: 600;
}
.botones{
    justify-content: center;
    align-items: flex-end;
    margin: 4rem 0;
}
.margin-btn-special{
    margin-bottom: 0.5rem;
}
.texto-check{
    margin-left: 5px;
    text-decoration: underline #1b668b ;
}
.display-flex-items{
	display: flex;
    align-items: center;
}
.plan{
	margin-left: -15px;
	text-align: center;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    padding: 30px;
}
.card-titulo{
    font-size: 24px;
	font-family: GothamBlack;
}
.img-logo-plan{
	width: 100%;
    height: 200px;
}
.img-icono{
	height: 25px;
    width: 25px;
    margin-right: 10px;
}
.border-bottom-blue{
	border-bottom: solid 1px #1b668b;
}
.margin-left{
	margin-left:36px;
}
.padding-0{
    padding: 0;
}
.padding-50{
    padding:50px;
    padding-top: 0;
}
.iconos-left-padding{
    padding: 0 0 0 40px;
}
.btn-azul-20{
    color: white;
    font-weight: 600;
    font-size: 16px;
    padding: 10px 12px;
    background-color: #1b668b;
    border-radius: 5px;
    margin: 0 5px; 
}
a.btn-azul-20:hover , a.btn-azul-20:active{
    outline: 0;
    background-color: #0b3c54;
    cursor: pointer;
}
/*selects*/
select {
    width: 100%;
    border: solid 1px #1b668b;
    border-radius: 5px;
    padding: 10px;
    height: 40px;
    margin-bottom: 1rem;
    background-color: white;
    outline: none;
    color: #5f5f5f;
    font-size: 16px;
    font-family: GothamLight;
}

.select-visacuotas {
    width: 94% !important;
}

.css-yk16xz-control {
    border: solid 1px #1b668b !important;
}
.css-1pahdxg-control {
    border: solid 1px #1b668b !important;
    box-shadow: 0 0 0 0 !important;
    border-color:     #1b668b !important;
}
.css-1pahdxg-control:hover .css-1pahdxg-control:active {
    border: solid 1px #1b668b !important;
    box-shadow: 0 0 0 0 #1b668b !important;
}
.css-1hwfws3 {
    height: 30px !important;
    cursor: pointer;
}
span.css-1okebmr-indicatorSeparator {
    background-color: #1b668b !important;
}
svg.css-6q0nyr-Svg {
    color: #1b668b !important;
}
/*Input uploadfile*/
.texto-titulo-uploadfile{
    color: #1b668b;
    font-size: 16px;
}
.text-uploadfile{
    margin-left: 10px;
    font-family: GothamLight;
}
label.filebutton {
    background-color: #91cc1b;
    padding: 15px 1rem;
    color: white;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}
label span input {
    display: none;
}
.struc-1{
    width: 75%;
    padding-left: 25px;
    padding-right: 25px;
}
.struc-2{
    width: 25%;
}
.text-tarjeta-date{
    color: #91cc1b;
}
.text-tarjeta-date-error{
    color: red;
}
/*Responsive*/
@media screen and (min-width:768px) and (max-width:1023px) {
    .margin-btn-special {
        flex: 0 0 50%;
        max-width: 37%;
    }
    @media (min-width: 425px) and (max-width:991px) {
        .col-sm-6p {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}
@media screen and (min-width:320px) and (max-width:767px) {
    .padding-0{
        padding: 0;
        text-align: center !important;
    }
    .linea-verde{
        display: none;
    }
    .margin-btn-special {
        margin-bottom: 2rem;
    }
    .struc-1{
        width: 100%;
        padding-left: 30px;
        padding-right: 0;
    }
    .struc-2{
        width: 100%;
        padding-left: 30px;
        padding-right: 0;
    }
    .MuiDialogActions-root {
        justify-content: center !important;
    }
    p#scroll-dialog-description {
        text-align: justify;
        padding: 25px;
    }
    h2.MuiTypography-root.MuiTypography-h6 {
        padding: 0 25px;
    }
    .btn-group-blue {
        padding: 10px 15px;
    }
    .btn-group-green {
        padding: 10px 15px;
    }
    .plan {
        margin-left: 0;
    }
    .margin-left {
        margin-left: 0;
    }
    .iconos-left-padding {
        padding: 0 0 0 0;
    }
    .justify-content-between {
        align-items: center;
        padding: 0px 15px 0 15px;
    }
    .select-pago-suscripcion{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .column-seleccion-pago{
        text-align: center;
    }
}