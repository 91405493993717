.simulacion-input{
	padding: 10px;
    height: 40px;
    margin-top: 1rem;
    outline: none;
    font-size: 16px;
    font-family: GothamLight;
    border: solid 1px #1b668b;
    border-radius: 5px;
    color: #005374;
    font-weight: 600;
    background-color: #fafafa;
    width: 19rem;
}
.q{
	background-color: #005374;
    padding: 10px;
    float: left;
    margin: -11px;
    border-radius: 5px 0 0 5px;
    color: white;
    font-weight: 600;
}
.left-inner-addon {
    position: relative;
    width: 19rem;
}
.left-inner-addon span {
    position: absolute;
    padding: 7px 12px;
    height: 40px;
    pointer-events: none;
    border-radius: 5px 0 0 5px;
    background-color: #005374;
    color: white;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.left-inner-addon > input{
    border: solid 1px #1b668b;
    border-radius: 5px;
    color: #005374;
    text-align: center;
    font-weight: 600;
    width: 100%;
}
.img-comofunciona{
    width: 100%;
    height: auto;
}
.img-calculadora{
    width: 100px;
    height: auto;
}
.p-calculadora{
    height: 3rem;
    margin-left: 1.4rem;
}
.height-title-calculadora{
    height: 3.5rem;
}
@media screen and (max-width:1023px){
    .left-inner-addon {
        width: 100%;
    }
    .simulacion-input {
        width: 100%;
    }
}
@media screen and (max-width: 767px) {
    .img-comofunciona {
        width: 30% !important;
    }
    .left-inner-addon {
        width: 18rem;
    }
    .simulacion-input {
        width: 18rem;
    }
}